export const getForumData = (state) => state.forumData;
export const getInstanceId = (state) => getForumData(state)._id;
export const getForumTitle = (state) => getForumData(state).label;
export const getPostLimiter = (state) => getForumData(state).newPostLimiter;
export const getAttachmentSizeLimit = (state) => getForumData(state).attachmentSizeLimit;
export const getCaptchaSettings = (state) => getForumData(state).captchaSettings || {};
export const getFilteredWords = (state) => getForumData(state).wordsFilter || [];
export const getIsForumSpammed = (state) => getForumData(state).isForumSpammed;
export const getIsPostingDisabled = (state) => getForumData(state).isPostingDisabled;
export const getForumCreatePostCta = (state) => getForumData(state).createPostCtaLabel;
